<script>
import EmbeddedApplicationNewView from '@/views/embeddedApplication/EmbeddedApplicationNewView'

export default {
  name: 'EmbeddedApplicationEditView',
  extends: EmbeddedApplicationNewView,
  created () {
    this.getEmbeddedApplication()
  }
}
</script>
